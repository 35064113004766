<template>
<div
    class="kt-header-menu-wrapper ml-0"
>
    <div class="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-default ml-0">
        <ul class="kt-menu__nav">
            <li
                v-for="section in sections"
                :key="`section_${section.title}`"
                class="kt-menu__item kt-menu__item--submenu"
                :class="section.show ? 'kt-menu__item--active' : 'kt-menu__item--hover'"
            >
                <a
                    :id="`link_${section.title}`"
                    ref="menu-link"
                    href="#"
                    class=""
                    :class="section.show ? 'btn kt-subheader__btn-daterange' : 'btn kt-subheader__btn-secondary kt-menu__link'"
                    @click.stop.prevent="toggleSection(section.title)"
                >
                    <span class="kt-menu__link-text">
                        {{ section.title }}
                    </span>
                </a>
            </li>
        </ul>
    </div>
    <div
        v-for="section in sections"
        :key="`pop_${section.title}`"
    >
        <b-popover
            v-if="section.items"
            triggers="focus"
            :target="`link_${section.title}`"
            placement="bottom"
            :show.sync="section.show"
            custom-class="menu-popover mt-4"
        >
            <div class="kt-widget4">
                <div
                    v-for="item in section.items"
                    :key="`item_${item.path}`"
                    class="kt-widget4__item"
                >
                    <span class="kt-widget4__icon">
                        <SVGIcon
                            hex-color="#0f88ef"
                            :name="item.icon"
                        />
                    </span>
                    <router-link
                        :to="{ name: item.name, params: { extCourseSectionId } }"
                        class="kt-widget4__title kt-widget4__title--light"
                    >
                        <i class="kt-menu__link-bullet kt-menu__link-bullet--dot"><span /></i>
                        <span class="kt-menu__link-text">
                            {{ item.title }}
                        </span>
                    </router-link>
                    <span class="d-none kt-widget4__number kt-font-info">
                        +500
                    </span>
                </div>
            </div>
        </b-popover>
    </div>
</div>
</template>
<script lang="ts">

import Vue from 'vue';

export default Vue.extend({
    name: 'StudentSubHeaderMenu',
    data() {
        return {
            sections: [{
                name: 'Letters',
                title: 'Letters',
                icon: 'attendance',
                path: 'letters',
            }],
        };
    },
    computed: {
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
    },
    methods: {
        toggleSection(title) {
            this.sections.map((s) => {
                const section = s;
                if (s.title === title) {
                    section.show = !s.show;
                    return section;
                }
                section.show = false;
                return section;
            });
        },
    },
});

</script>

<style>

.menu-popover .arrow {
    visibility: hidden;
}
</style>

<style scoped>
.menu-popover {
    min-width: 240px;
}
.kt-subheader__btn-daterange .kt-menu__link-text {
    color: #007bff !important;
    font-weight: 500;
}
.kt-subheader__btn-secondary .kt-menu__link-text {
    color: #434349 !important;
}
</style>
