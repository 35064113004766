var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-portlet" }, [
    _c("div", { staticClass: "kt-portlet__head kt-portlet__head--lg" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c(
          "span",
          { staticClass: "kt-portlet__head-icon pr-0" },
          [
            _c("SVGIcon", {
              staticClass: "mr-3",
              attrs: { "hex-color": "#0f88ef", name: "attendance" },
            }),
          ],
          1
        ),
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _c("span", [
            _vm._v(
              " " +
                _vm._s(_vm.monthName) +
                " " +
                _vm._s(_vm.year) +
                " Attendance "
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "kt-portlet__body" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm.selectedMonth
            ? _c(
                "div",
                { staticClass: "py-2" },
                [
                  _c("b-calendar", {
                    key: `${_vm.month}_${_vm.year}`,
                    staticClass: "pl-2 mt-2",
                    attrs: {
                      "date-info-fn": _vm.dateClass,
                      "label-help": "",
                      "show-decade-nav": false,
                      "no-key-nav": true,
                      "hide-header": true,
                      readonly: true,
                      "initial-date": _vm.date,
                      block: "",
                    },
                    on: { context: _vm.onContext },
                  }),
                  _c("AttendanceLegend"),
                ],
                1
              )
            : _vm._e(),
          _c("p", { staticClass: "kt-font-lg pl-3 pt-4" }, [
            _vm._v(" Attendance Totals "),
          ]),
          _c("div", { staticClass: "w-100 px-4" }, [
            _c("div", { staticClass: "col-12 pt-2 pb-2" }, [
              _c("div", [
                _c("span", [_vm._v("Present")]),
                _c("span", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.yearToDate.present.percentage) + "%"),
                ]),
              ]),
              _c("div", { staticClass: "progress progress-sm" }, [
                _c("div", {
                  staticClass: "progress-bar bg-success",
                  style: `width: ${_vm.yearToDate.present.percentage}%`,
                  attrs: { role: "progressbar" },
                }),
              ]),
            ]),
            _c("div", { staticClass: "col-12 pt-2 pb-2" }, [
              _c("div", [
                _c("span", [_vm._v("Absence")]),
                _c("span", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.yearToDate.absent.instances)),
                ]),
              ]),
              _c("div", { staticClass: "progress progress-sm" }, [
                _c("div", {
                  staticClass: "progress-bar bg-danger",
                  style: `width: ${_vm.yearToDate.absent.percentage}%`,
                  attrs: { role: "progressbar" },
                }),
              ]),
            ]),
            _c("div", { staticClass: "col-12 pt-2 pb-2" }, [
              _c("div", [
                _c("span", [_vm._v("Late")]),
                _c("span", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.yearToDate.late.instances)),
                ]),
              ]),
              _c("div", { staticClass: "progress progress-sm" }, [
                _c("div", {
                  staticClass: "progress-bar bg-warning",
                  style: `width: ${_vm.yearToDate.late.percentage}%`,
                  attrs: { role: "progressbar" },
                }),
              ]),
            ]),
            _c("div", { staticClass: "col-12 pt-2 pb-2" }, [
              _c("div", [
                _c("span", [_vm._v("Excused")]),
                _c("span", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.yearToDate.excused.instances)),
                ]),
              ]),
              _c("div", { staticClass: "progress progress-sm" }, [
                _c("div", {
                  staticClass: "progress-bar bg-primary",
                  style: `width: ${_vm.yearToDate.excused.percentage}%`,
                  attrs: { role: "progressbar" },
                }),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }