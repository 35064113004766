<template>
<div class="p-3 row">
    <div class="col-6">
        <span
            class="colorSwatch"
            style="background-color: #baecdd"
        />
        <small class="text-muted">Present</small>
    </div>
    <div class="col-6">
        <span
            class="colorSwatch"
            style="background-color: #fec8da"
        />
        <small class="text-muted">Absent</small>
    </div>
    <div class="col-6">
        <span
            class="colorSwatch"
            style="background-color: #ffebc1"
        />
        <small class="text-muted">Late</small>
    </div>
    <div class="col-6">
        <span
            class="colorSwatch"
            style="background-color: #cfd9f9"
        />
        <small class="text-muted">Excused</small>
    </div>
</div>
</template>

<script>

export default {
    name: 'AttendanceLegend',

};
</script>
