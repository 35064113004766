import moment from 'moment';
// import Joi from 'joi';
import StudentGrade from './models/StudentGrade';

function makeUnique(arr, comparisionProperty) {
    const output = [];
    const lookup = {};
    arr.forEach((item) => {
        if (!lookup.hasOwnProperty(item[comparisionProperty])) {
            output.push(item);
        }
        lookup[item[comparisionProperty]] = true;
    });
    return output;
}

function inRange(assignment, markingPeriod) {
    if (!assignment.sortDate) {
        return false;
    }
    const betweenDates = moment.utc(assignment.sortDate).isBetween(markingPeriod.start, markingPeriod.end, 'days', '[]');
    return (assignment.schoolTermMarkingPeriodId == markingPeriod.schoolTermMarkingPeriodId)
        || (betweenDates && !assignment.averageExclusiveForMp);
}
function round(value, decimals) {
    // @ts-ignore
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}

function scaleCourseWorkGrade(gradeTemplate, courseWork, courseWorkGrade) {
    const studentGrade = new StudentGrade(courseWorkGrade);
    const studentCourseWorkGrade = { ...courseWork, ...studentGrade };
    const { averageMethod, floorAverages } = gradeTemplate;
    if (courseWork.googleCourseId) {
        studentCourseWorkGrade.grade.syncGrades.assignedGrade = courseWorkGrade.assignedGrade;
        if (courseWorkGrade.assignedGrade === null || courseWorkGrade.assignedGrade === '') studentCourseWorkGrade.grade.syncGrades.assignedGrade = courseWorkGrade.draftGrade;
    }
    // if (courseWork.googleCourseId && courseWorkGrade.assignedGrade) debugger;
    // validate the object
    let percent = 0;
    if (studentCourseWorkGrade.maxPoints && studentCourseWorkGrade.grade.syncGrades && studentCourseWorkGrade.grade.syncGrades.assignedGrade) {
        percent = round(studentCourseWorkGrade.grade.syncGrades.assignedGrade / studentCourseWorkGrade.maxPoints || 0, 4);
    }

    if (floorAverages && (percent * 100) > floorAverages) {
        percent = (floorAverages / 100);
    }

    studentCourseWorkGrade.grade.syncGrades.percentEarned = percent;
    studentCourseWorkGrade.grade.syncGrades.isExcusedGrade = false;

    const validMark = gradeTemplate.marks.find((m) => m.gradeTemplateMark == studentGrade.mark);
    if (validMark && validMark.numericPassthrough == null) {
        studentCourseWorkGrade.grade.syncGrades.isExcusedGrade = true;
    }

    // mark if late deduction applies
    studentCourseWorkGrade.grade.syncGrades.latePercentToDeduct = 0;

    // pass averaging method
    studentCourseWorkGrade.grade.syncGrades.averageMethod = averageMethod;

    const { grade } = studentCourseWorkGrade;
    if (!grade) { return studentCourseWorkGrade; }
    const { syncGrades, google } = grade;

    syncGrades.pointsPossible = 0;
    syncGrades.pointsEarned = 0;
    syncGrades.percentEarned = 0;
    syncGrades.lateDeductionApplied = 0;

    const gradeTemplateMark = gradeTemplate.marks.find((m) => m.gradeTemplateMark == studentGrade.mark) || null;

    const { courseWorkWeight } = studentCourseWorkGrade;
    const gradingScales = gradeTemplate.scale.grades;

    const isNumeric = Boolean(courseWorkGrade.mark !== '' && courseWorkGrade.mark !== null && !Number.isNaN(parseFloat(courseWorkGrade.mark)));
    // define numeric value
    let numericPassthrough = isNumeric ? parseFloat(courseWorkGrade.mark) : null;
    if (gradeTemplateMark) numericPassthrough = parseFloat(gradeTemplateMark.numericPassthrough);
    if (Number.isNaN(numericPassthrough)) numericPassthrough = null;
    syncGrades.excludedFromAverage = Boolean(numericPassthrough == null);

    const inputtedGrade = !google ? syncGrades.assignedGrade : google.assignedGrade !== '' && google.assignedGrade !== null ? google.assignedGrade : google.draftGrade;
    let pointsPossible = google ? google.pointsPossible : studentCourseWorkGrade.maxPoints;
    if (syncGrades.excludedFromAverage || inputtedGrade === null) {
        pointsPossible = 0;
        syncGrades.color = gradeTemplateMark ? gradeTemplateMark.gradeTemplateMarkColor : 'Gray';
        // return studentCourseWorkGrade;
    }

    if (!google) {
        const markLookup = { // set defaults
            gradeTemplateMark: courseWorkGrade.mark,
            color: null,
            numericPassthrough,
            gradeTemplateId: 0,
        };

        let isValidMark = false;

        if (gradeTemplateMark) { // mark exists
            markLookup.color = gradeTemplateMark.gradeTemplateMarkColor;
            if (isNumeric) markLookup.numericPassthrough = gradeTemplateMark.numericPassthrough;
            else markLookup.numericPassthrough = (markLookup.numericPassthrough / 100) * pointsPossible;
            markLookup.gradeTemplateId = gradeTemplate.gradeTemplateId;
            isValidMark = true;
        }

        if (isNumeric && !gradeTemplateMark) {
            isValidMark = true;
            markLookup.gradeTemplateId = gradeTemplate.gradeTemplateId;
            markLookup.numericPassthrough = parseFloat(courseWorkGrade.mark);
        }

        if (pointsPossible == 0 || markLookup.numericPassthrough === null) {
            syncGrades.pointsPossible = 0;
            syncGrades.pointsEarned = 0;
        }

        if (isValidMark && pointsPossible > 0) {
            const pointsEarned = markLookup.numericPassthrough;
            if (pointsPossible > 0) {
                const percentEarned = round(pointsEarned / pointsPossible, 4);
                syncGrades.percentEarned = percentEarned;
                if (floorAverages && (percentEarned * 100) > floorAverages) {
                    syncGrades.percentEarned = (floorAverages / 100);
                }
            }

            // apply a grade scale to the points and percent object
            let roundedPercent = Math.round(syncGrades.percentEarned * 100.0);

            // points is only different from percents when calculating category averages
            let points = gradingScales.find((scale) => (roundedPercent >= scale.minGrade && roundedPercent <= scale.maxGrade));
            if (roundedPercent > 100 && !points) points = gradingScales.reduce((prev, curr) => ((prev && prev.minGrade > curr.minGrade) ? prev : curr));
            if (syncGrades.scaled) syncGrades.scaled.points = points || null;

            if (roundedPercent > 100) roundedPercent = 100;
            if (syncGrades.scaled) syncGrades.scaled.percent = gradingScales.find((scale) => (roundedPercent >= scale.minGrade && roundedPercent <= scale.maxGrade)) || null;

            if (averageMethod == 'Points') {
                syncGrades.pointsPossible = pointsPossible * courseWorkWeight;
                syncGrades.pointsEarned = pointsEarned * courseWorkWeight;
            } else {
                syncGrades.pointsPossible = round(((pointsPossible / pointsPossible) * 100) * courseWorkWeight, 4);
                syncGrades.pointsEarned = round(((pointsEarned / pointsPossible) * 100) * courseWorkWeight, 4);
            }
        }

        return studentCourseWorkGrade;
    }

    const mark = google.assignedGrade !== null && google.assignedGrade !== '' ? google.assignedGrade : google.draftGrade;
    if (mark === '' || mark === null) {
        // studentCourseWorkGrade.excluded = true;
        studentCourseWorkGrade.grade.google.isExcusedGrade = true;
        studentCourseWorkGrade.grade.syncGrades.isExcusedGrade = true;
        return studentCourseWorkGrade;
    }

    // google to syncGrades conversion below
    // calculates earned/possible for google assignment
    const { pointsEarned } = google;
    pointsPossible = google.pointsPossible || studentCourseWorkGrade.maxPoints;
    if (google.pointsPossible == 0) {
        syncGrades.pointsPossible = 0;
        syncGrades.pointsEarned = 0;
    }

    if (averageMethod == 'Points') {
        syncGrades.pointsPossible = pointsPossible * courseWorkWeight;
        syncGrades.pointsEarned = pointsEarned * courseWorkWeight;
    } else {
        syncGrades.pointsPossible = round(((pointsPossible / pointsPossible) * 100) * courseWorkWeight, 4);
        syncGrades.pointsEarned = round(((pointsEarned / pointsPossible) * 100) * courseWorkWeight, 4);
    }

    if (syncGrades.pointsPossible > 0) {
        syncGrades.percentEarned = round(syncGrades.pointsEarned / syncGrades.pointsPossible, 4);
    }

    // apply a grade scale to the points and percent object
    let googlePercent = syncGrades.percentEarned ? Math.round(syncGrades.percentEarned * 100.0) : 0;

    // points is only different from percents when calculating category averages
    const points = gradingScales.find((scale) => (googlePercent >= scale.minGrade && googlePercent <= scale.maxGrade));
    if (syncGrades.scaled) syncGrades.scaled.points = points || null;

    if (googlePercent > 100) googlePercent = 100;
    if (syncGrades.scaled) syncGrades.scaled.percent = gradingScales.find((scale) => (googlePercent >= scale.minGrade && googlePercent <= scale.maxGrade)) || null;

    if (averageMethod == 'Points') {
        syncGrades.pointsPossible = pointsPossible * courseWorkWeight;
        syncGrades.pointsEarned = pointsEarned * courseWorkWeight;
    } else {
        syncGrades.pointsPossible = round(((pointsPossible / pointsPossible) * 100) * courseWorkWeight, 4);
        syncGrades.pointsEarned = round(((pointsEarned / pointsPossible) * 100) * courseWorkWeight, 4);
    }

    return studentCourseWorkGrade;
}

function calculateAssignmentStatus(a) {
    const assignment = { ...a };

    /*
        `Completed` A graded assignment for a student,
            regardless of whether the submission has been turned in by the student.
        `Upcoming` An ungraded assignment for a student
            who has not turned in a submission, that is also before the assignment Due Date.
            If Due Date is not defined, the assignment creation date is used.
        `Turned In` An ungraded assignment for a student
            who has turned in a submission. The due date is irrelevant.
        `Missing` An ungraded assignment for a student
            who has not yet turned in a submission, and is past the Due Date.
            If Due Date is not defined, the assignment creation date is used.
        `Unknown` An assignment that the system could not labeled
    */

    const state = {
        status: 'Unknown',
        color: 'Grey',
        icon: 'la la-question-circle ',
        tooltip: 'This assignment could not be labeled by SyncGrades',
    };
    assignment.state = state;
    if (assignment.courseWorkId) return assignment; // for googlecourse work only

    let grade = null;
    const pointTypeUsed = assignment.grade?.google?.pointTypeUsed;
    if (pointTypeUsed == 'assignedGrade') grade = assignment.grade.google.assignedGrade;
    if (pointTypeUsed == 'draftGrade') grade = assignment.grade.google.draftGrade;

    if (grade !== null && assignment.grade?.syncGrades) {
        assignment.state.status = 'Completed';
        assignment.state.color = 'Purple';
        assignment.state.icon = 'la la-flag-checkered';
        assignment.state.tooltip = 'This assignment has been graded.';
        return assignment;
    }

    if (!assignment.grade?.syncGrades) {
        return assignment;
    }

    const submitted = ['TURNED_IN', 'RETURNED'].includes(assignment.grade?.google?.submissionState);
    const pastDue = moment.utc(assignment.sortDate).isAfter(moment.utc());
    if (!submitted) {
        if (pastDue) {
            assignment.state.status = 'Missing';
            assignment.state.color = 'Red';
            assignment.state.icon = 'la la-times-circle';
            assignment.state.tooltip = 'This assignment is past due and is missing.';
            return assignment;
        }
        assignment.state.status = 'Upcoming';
        assignment.state.color = 'Orange';
        assignment.state.icon = 'la la-clock-o';
        assignment.state.tooltip = 'This assignment is not yet due and student work has not yet been submitted to google.';
        return assignment;
    }
    return assignment;
}

function calculateTotals(mp, gradeTemplate) {
    // sum points possible + earned, calculate category avg
    const markingPeriod = mp;
    markingPeriod.categories.forEach((c) => {
        const category = c;
        if (category.gradeTemplateCategoryId == 0) {
            return;
        }

        if (category.assignments.length > 0) {
            category.pointsPossible = category.assignments.reduce((a, b) => {
                let possible = 0;
                if (b.grade && b.grade.syncGrades && !Number.isNaN(b.grade.syncGrades.pointsPossible)) {
                    possible = b.grade.syncGrades.pointsPossible;
                }
                return a + possible;
            }, 0);

            category.pointsEarned = category.assignments.reduce((a, b) => {
                let earned = 0;
                if (b.grade && b.grade.syncGrades && !Number.isNaN(b.grade.syncGrades.pointsEarned)) {
                    earned = b.grade.syncGrades.pointsEarned;
                }
                return a + earned;
            }, 0);

            // simple counts, not needed in averging
            category.totals.all = category.assignments.length;
            category.totals.late = category.assignments.filter((ass) => ass.grade && ass.grade.google && ass.grade.google.late).length;
            category.totals.excused = category.assignments.filter((ass) => ass.grade && (ass.grade.syncGrades.isExcusedGrade || ass.grade.syncGrades.isLowest)).length;
            category.totals.missing = category.assignments.filter((ass) => !ass.grade).length;
            category.totals.pass = category.assignments
                .filter((ass) => ass.grade && ass.grade.syncGrades.scaled && ass.grade.syncGrades.scaled.percent && ass.grade.syncGrades.scaled.percent.nycPassing).length;
            category.totals.fail = category.assignments
                .filter((ass) => ass.grade && ass.grade.syncGrades.scaled && ass.grade.syncGrades.scaled.percent && !ass.grade.syncGrades.scaled.percent.nycPassing).length;
        }

        if (category.pointsPossible > 0) {
            category.percentEarned = round(category.pointsEarned / category.pointsPossible, 4);
        }
        if (category.percentEarned == 0) {
            if (category.gradeTemplateCategoryId != 0) {
                // const countedAssignments = category.assignments.filter((a) => {
                //     if (!a.grade) return false;
                //     return !a.grade.syncGrades.hasOwnProperty('isLowest') || a.grade.syncGrades.isLowest === false;
                // });

                // prorated categories with no grades
                const categoryUngraded = category.assignments.filter((a) => {
                    if (a.excluded || a.deleted || a.hideMarks) return true;
                    if (a.googleCourseWorkId) {
                        // debugger;
                        if (!a.grade || !a.grade.google) return true;
                        const { assignedGrade, draftGrade } = a.grade.google;
                        const mark = assignedGrade !== null && assignedGrade !== '' ? assignedGrade : draftGrade;
                        if (mark === null || mark === '') return true;
                        return false;
                    }
                    if (a.courseWorkId) {
                        if (!a.grade || !a.grade.syncGrades) return true;
                        const { isExcusedGrade, excludedFromAverage, assignedGrade } = a.grade.syncGrades;
                        const { isLowest } = a.grade.syncGrades;
                        if (isExcusedGrade || excludedFromAverage || isLowest) return true;
                        if (assignedGrade === null || assignedGrade === '') return true;
                        return false;
                    }
                    return true;
                });

                if (categoryUngraded.length === category.assignments.length || category.pointsPossible === 0) {
                    category.prorated = true;
                    category.categoryWorth = 0;
                }
            }
        }
        if (!category.prorated) {
            category.categoryEarned = round(category.percentEarned * category.categoryWorth, 4);

            // scale
            let roundedAverage = Math.round(category.percentEarned * 100.0);
            if (roundedAverage > 100) roundedAverage = 100;
            category.scaled = gradeTemplate.scale.grades.find((scale) => roundedAverage >= scale.minGrade && roundedAverage <= scale.maxGrade);
        }
    });

    const allProRated = markingPeriod.categories.every((category) => category.prorated || category.categoryName == 'Uncategorized');

    markingPeriod.average = 0;
    if (allProRated) return markingPeriod;

    // overall average
    markingPeriod.sumCategoryWorth = markingPeriod.categories.reduce((a, b) => a + (b.categoryWorth || 0), 0);
    markingPeriod.sumCategoryWorth = round(markingPeriod.sumCategoryWorth, 4);

    markingPeriod.sumCategoryEarned = markingPeriod.categories.reduce((a, b) => a + (b.categoryEarned || 0), 0);
    markingPeriod.sumCategoryEarned = round(markingPeriod.sumCategoryEarned, 4);

    if (markingPeriod.sumCategoryWorth > 0) {
        markingPeriod.average = round(markingPeriod.sumCategoryEarned / markingPeriod.sumCategoryWorth, 4);
    }
    let roundedAverage = Math.round(markingPeriod.average * 100.0);
    if (roundedAverage > 100) roundedAverage = 100;

    markingPeriod.scaled = gradeTemplate.scale.grades.find((scale) => roundedAverage >= scale.minGrade && roundedAverage <= scale.maxGrade);

    return markingPeriod;
}

export {
    makeUnique,
    inRange,
    calculateTotals,
    round,
    // calculateCourseWorkGrade,
    calculateAssignmentStatus,
    scaleCourseWorkGrade,
};
