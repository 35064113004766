var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "kt-portlet kt-portlet--fit kt-portlet--head-noborder" },
    [
      _c("div", { staticClass: "kt-portlet__head kt-portlet__space-x" }, [
        _c("div", { staticClass: "kt-portlet__head-label" }, [
          _c(
            "span",
            { staticClass: "kt-portlet__head-icon" },
            [
              _c("SVGIcon", {
                attrs: {
                  name: "averages",
                  "hex-color": _vm.category
                    ? _vm.category.color || "Grey"
                    : "Grey",
                },
              }),
            ],
            1
          ),
          _c("h3", { staticClass: "kt-portlet__head-title" }, [
            _vm._v(
              " Marking Period " + _vm._s(_vm.markingPeriod.markingPeriod) + " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
          _c(
            "span",
            {
              staticClass: "kt-font-bold font",
              class: _vm.category ? _vm.category.color || "Grey" : "Grey",
            },
            [_vm._v(" " + _vm._s(_vm.selectedGradingCategory) + " Average ")]
          ),
        ]),
      ]),
      _vm.breakdown
        ? _c(
            "div",
            { staticClass: "kt-portlet__body" },
            [
              _vm.assignments.length > 0
                ? [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "kt-widget20" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "kt-widget20__content kt-portlet__space-x",
                            },
                            [
                              _vm.category && _vm.category.scaled
                                ? _c("div", { staticClass: "media" }, [
                                    _vm.showScaledScores
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mr-3 align-self-center kt-badge kt-badge--xl scaleColorBadge kt-font-xl",
                                            class: _vm.category.scaled.color,
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.category.scaled.mark
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("div", { staticClass: "media-body" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "kt-widget20__number font",
                                          class: _vm.category.scaled.color,
                                          staticStyle: { height: "32px" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.category.scaled.displayName
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("br"),
                                      _vm.showPercentages
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "kt-widget20__desc",
                                            },
                                            [
                                              _vm._v(
                                                " Average: " +
                                                  _vm._s(
                                                    _vm.decimalToPercent(
                                                      _vm.category.percentEarned
                                                    )
                                                  ) +
                                                  " % "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "kt-widget20",
                        staticStyle: { "padding-bottom": "20px" },
                      },
                      [
                        _vm.category && _vm.category.scaled
                          ? _c(
                              "div",
                              {
                                staticClass: "kt-widget20__chart",
                                staticStyle: { height: "auto" },
                              },
                              [
                                _c("StudentBreakdownLineChart", {
                                  key: `schoolTermMarkingPeriodId_${_vm.schoolTermMarkingPeriodId}`,
                                  attrs: {
                                    category: _vm.category,
                                    assignments: _vm.assignments,
                                    height: 60,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c("div", { staticClass: "kt-widget15 px-3" }, [
                      _c("div", { staticClass: "kt-widget15__items" }, [
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { padding: "10px 20px 0 20px" },
                          },
                          _vm._l(_vm.categories, function (cat) {
                            return _c(
                              "div",
                              {
                                key: "c" + cat.gradeTemplateCategoryId,
                                staticClass: "col-sm-12 col-md-6 col-xl-4",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "kt-widget15__item" },
                                  [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c(
                                        "div",
                                        { staticClass: "kt-radio-list" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "kt-radio kt-radio--bold font",
                                              class: cat.color,
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.selectedGradingCategory,
                                                    expression:
                                                      "selectedGradingCategory",
                                                  },
                                                ],
                                                staticStyle: {
                                                  "margin-top": "10px",
                                                },
                                                attrs: {
                                                  type: "radio",
                                                  name: "categoryCheck",
                                                },
                                                domProps: {
                                                  value: cat.categoryName,
                                                  checked: Boolean(
                                                    _vm.selectedGradingCategory ==
                                                      cat.categoryName
                                                  ),
                                                  checked: _vm._q(
                                                    _vm.selectedGradingCategory,
                                                    cat.categoryName
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.setCategory(
                                                      cat.categoryName
                                                    )
                                                  },
                                                  change: function ($event) {
                                                    _vm.selectedGradingCategory =
                                                      cat.categoryName
                                                  },
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(cat.categoryName) +
                                                  " "
                                              ),
                                              _c("span", { class: cat.color }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "form-text",
                                          staticStyle: {
                                            "margin-left": "30px",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setCategory(
                                                cat.categoryName
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "progress m-progress--sm",
                                              staticStyle: {
                                                "margin-bottom": "6px",
                                              },
                                            },
                                            [
                                              cat.scaled
                                                ? _c("div", {
                                                    staticClass:
                                                      "progress-bar categoryAverageBar",
                                                    class: cat.scaled.color,
                                                    style: {
                                                      width:
                                                        _vm.decimalToPercent(
                                                          cat.percentEarned
                                                        ) + "%",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                          cat.scaled
                                            ? [
                                                !cat.prorated
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font kt-font-bold",
                                                        class: cat.scaled.color,
                                                        staticStyle: {
                                                          "margin-right": "2px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" Average:   "),
                                                        _vm.showPercentages
                                                          ? [
                                                              _c("samp", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.decimalToPercent(
                                                                      cat.percentEarned
                                                                    )
                                                                  ) + "%"
                                                                ),
                                                              ]),
                                                              _vm._v("    "),
                                                            ]
                                                          : _vm._e(),
                                                        _vm.showScaledScores
                                                          ? _c("samp", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    cat.scaled
                                                                      .mark
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Prorated / No Grades In Category "
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _c("div", {
                      staticClass:
                        "kt-separator kt-separator--space-sm kt-separator--border-dashed",
                    }),
                  ]
                : _vm._e(),
              _c("div", { staticClass: "py-3 px-5" }, [
                _c(
                  "div",
                  { staticClass: "kt-section" },
                  [
                    _c(
                      "span",
                      { staticClass: "kt-section__title kt-font-lg ml-1 mt-3" },
                      [
                        _vm._v(
                          " Marking Period " +
                            _vm._s(_vm.markingPeriod.markingPeriod) +
                            " Assignments: "
                        ),
                      ]
                    ),
                    _c("b-table", {
                      attrs: {
                        id: "average-table",
                        items: _vm.assignments,
                        fields: _vm.fields,
                        stacked: "sm",
                        "sort-null-last": "",
                        responsive: "",
                        fixed: "",
                        "selected-variant": "",
                        "show-empty": "",
                        hover: "",
                        small: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(mark)",
                            fn: function (row) {
                              return [
                                !row.item.graded || row.item.hideMarks
                                  ? _c("div", { staticClass: "font Grey" }, [
                                      _vm._v(" - "),
                                    ])
                                  : _c(
                                      "div",
                                      {
                                        class: [
                                          "font",
                                          "kt-font-bold",
                                          "kt-font-lg",
                                          row.item.averageMethod ==
                                          "Percentages"
                                            ? row.item.percentColor
                                            : row.item.pointsColor,
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.item.assignedGrade) +
                                            " "
                                        ),
                                      ]
                                    ),
                              ]
                            },
                          },
                          {
                            key: "cell(categoryName)",
                            fn: function (row) {
                              return [
                                _c("div", { staticClass: "pt-2" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-nowrap text-truncate",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "kt-font kt-font-transform-u",
                                          class: row.item.categoryColor,
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(row.item.categoryName) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(courseWorkTitle)",
                            fn: function (row) {
                              return [
                                _c("div", { staticClass: "mt-1 mb-1" }, [
                                  _c("div", { staticClass: "course-title" }, [
                                    row.item.courseWorkId
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "kt-link font",
                                            class: row.item.categoryColor,
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.showDetails(row.item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                class: row.item.deleted
                                                  ? "kt-font-danger"
                                                  : "",
                                              },
                                              [
                                                row.item.teacherExtCourseWorkId
                                                  ? _c(
                                                      "samp",
                                                      {
                                                        staticClass: "font",
                                                        class:
                                                          row.item
                                                            .categoryColor,
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              row.item
                                                                .teacherExtCourseWorkId
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.item.courseWorkTitle
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    row.item.googleCourseWorkId
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "kt-link font",
                                            class: row.item.categoryColor,
                                            attrs: {
                                              href: row.item.alternateLink,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _c("SVGIcon", {
                                              staticClass: "mr-2",
                                              attrs: { name: "google" },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  row.item.courseWorkTitle
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(publishDate)",
                            fn: function (row) {
                              return [
                                _c("div", { staticClass: "mt-1 mb-1" }, [
                                  _vm._v(
                                    " " + _vm._s(row.item.publishDate) + " "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(dueDate)",
                            fn: function (row) {
                              return [
                                _c("div", { staticClass: "mt-1 mb-1" }, [
                                  _vm._v(" " + _vm._s(row.item.dueDate) + " "),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3943421617
                      ),
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }