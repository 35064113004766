<template>
<div :key="`key_student-course-overview_${key}`">
    <StudentCourseSubHeader
        v-if="student && course"
        :student="student"
        :course="course"
    >
        <template #right-buttons>
            <StudentSubHeaderMenu />
        </template>
    </StudentCourseSubHeader>
    <div
        v-if="isPortfolioReady"
        class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"
    >
        <StudentHeaderPortlet
            v-if="$_userMixins_isSchoolUser && !$store.state.forceStudentView"
            :display-wide="true"
        />

        <div
            v-if="deviceType == 'desktop' "
            class="row"
        >
            <div class="col-7">
                <StudentAverageBreakdown />
            </div>
            <div class="col-5">
                <StudentCourseAverages />
                <StudentCourseAttendance />
            </div>
        </div>
        <div
            v-if="deviceType == 'tablet' || deviceType == 'laptop'"
            class="row"
        >
            <div class="col-6">
                <StudentCourseAttendance />
            </div>
            <div class="col-6">
                <StudentCourseAverages />
            </div>
            <div class="col-12">
                <StudentAverageBreakdown />
            </div>
        </div>
        <div
            v-if="deviceType == 'mobile'"
            class="row"
        >
            <div class="col-12">
                <StudentCourseAttendance />
            </div>
            <div class="col-12">
                <StudentCourseAverages />
            </div>
            <div class="col-12">
                <StudentAverageBreakdown />
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import courseMixins from '../store/mixins/courseMixins';
import studentMixins from '../store/mixins/studentMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import userMixins from '../store/mixins/userMixins';
import averagingMixins from '../store/mixins/averagingMixins';
import StudentCourseSubHeader from './StudentCourseSubHeader.vue';
import StudentSubHeaderMenu from './StudentSubHeaderMenu.vue';

import StudentCourseAttendance from '../components/StudentCourseAttendance.vue';
import StudentCourseAverages from '../components/StudentCourseAverages.vue';
import StudentHeaderPortlet from '../components/StudentHeaderPortlet.vue';
import StudentAverageBreakdown from '../components/StudentAverageBreakdown.vue';

export default Vue.extend({
    name: 'StudentCourseOverview',
    components: {
        StudentCourseAttendance,
        StudentCourseAverages,
        StudentCourseSubHeader,
        StudentSubHeaderMenu,
        StudentHeaderPortlet,
        StudentAverageBreakdown,
    },
    mixins: [
        userMixins,
        courseMixins,
        studentMixins,
        portfolioMixins,
        averagingMixins,
    ],
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        deviceType() {
            return this.$store.state.deviceType;
        },
        isPortfolioReady() {
            const cacheKey = this.$_portfolioMixins_getPortfolioCacheKey;
            return Boolean((this.$store.state.database.cache).find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        student() {
            const { studentEnrollmentId } = this;
            const students = this.$_studentMixins_getStudents({ studentEnrollmentId });
            const [student] = students;
            return student || null;
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        markingPeriods() {
            return this.$store.state.database.markingPeriods;
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        averagesCached() {
            const { extCourseSectionId, studentEnrollmentId } = this;
            const { schoolTermMarkingPeriodId } = this.$store.state.settings;
            const cache = this.$store.state.database.cache.find((c) => c.key == `portfolioCalc_${studentEnrollmentId}_${extCourseSectionId}_${schoolTermMarkingPeriodId}`) || null;
            return Boolean(cache);
        },
    },
    watch: {
        studentEnrollmentId() {
            this.calculateAverages();
        },
        extCourseSectionId() {
            this.calculateAverages();
        },
        isPortfolioReady() {
            this.calculateAverages();
        },
    },
    mounted() {
        this.calculateAverages();
    },
    methods: {
        calculateAverages() {
            const { course, student, averagesCached } = this;
            if (!this.isPortfolioReady) return;
            if (!student || !course) return;

            const { courseSectionId } = course;
            const { studentEnrollmentId } = student;
            if (!averagesCached || !studentEnrollmentId) {
                this.$_averagingMixins_calculateAverageForStudentInCourse(courseSectionId, studentEnrollmentId);
            }
        },
    },
});
</script>
