<template>
<div class="kt-portlet kt-portlet--fit kt-portlet--head-noborder">
    <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon">
                <SVGIcon
                    name="averages"
                    :hex-color="category ? category.color || 'Grey' : 'Grey'"
                />
            </span>
            <h3 class="kt-portlet__head-title">
                Marking Period {{ markingPeriod.markingPeriod }}
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <span
                class="kt-font-bold font"
                :class="category ? category.color || 'Grey' : 'Grey'"
            >
                {{ selectedGradingCategory }} Average
            </span>
        </div>
    </div>
    <div
        v-if="breakdown"
        class="kt-portlet__body"
    >
        <template v-if="assignments.length > 0">
            <div class="row">
                <div class="col-12">
                    <div class="kt-widget20">
                        <div class="kt-widget20__content kt-portlet__space-x">
                            <div
                                v-if="category && category.scaled"
                                class="media"
                            >
                                <div
                                    v-if="showScaledScores"
                                    class="mr-3 align-self-center kt-badge kt-badge--xl scaleColorBadge kt-font-xl"
                                    :class="category.scaled.color"
                                >
                                    {{ category.scaled.mark }}
                                </div>
                                <div class="media-body">
                                    <span
                                        class="kt-widget20__number font"
                                        :class="category.scaled.color"
                                        style="height: 32px;"
                                    >
                                        {{ category.scaled.displayName }}
                                    </span>
                                    <br>
                                    <span
                                        v-if="showPercentages"
                                        class="kt-widget20__desc"
                                    >
                                        Average: {{ decimalToPercent(category.percentEarned) }} %
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="kt-widget20"
                style="padding-bottom: 20px"
            >
                <div
                    v-if="category && category.scaled"
                    class="kt-widget20__chart"
                    style="height:auto;"
                >
                    <!-- :clicked-assignment="openAssignmentModal" -->
                    <StudentBreakdownLineChart
                        :key="`schoolTermMarkingPeriodId_${schoolTermMarkingPeriodId}`"
                        :category="category"
                        :assignments="assignments"
                        :height="60"
                    />
                </div>
            </div>
            <div class="kt-widget15 px-3">
                <div class="kt-widget15__items">
                    <div
                        class="row"
                        style="padding: 10px 20px 0 20px;"
                    >
                        <div
                            v-for="cat in categories"
                            :key="'c'+cat.gradeTemplateCategoryId"
                            class="col-sm-12 col-md-6 col-xl-4"
                        >
                            <div class="kt-widget15__item">
                                <div class="form-group">
                                    <div class="kt-radio-list">
                                        <label
                                            class="kt-radio kt-radio--bold font"
                                            :class="cat.color"
                                        >
                                            <input
                                                v-model="selectedGradingCategory"
                                                type="radio"
                                                style="margin-top: 10px"
                                                name="categoryCheck"
                                                :value="cat.categoryName"
                                                :checked="Boolean(selectedGradingCategory == cat.categoryName)"
                                                @click="setCategory(cat.categoryName)"
                                            >
                                            {{ cat.categoryName }}
                                            <span :class="cat.color" />
                                        </label>
                                    </div>
                                    <span
                                        class="form-text"
                                        style="margin-left: 30px; cursor: pointer"
                                        @click="setCategory(cat.categoryName)"
                                    >
                                        <div
                                            class="progress m-progress--sm"
                                            style="margin-bottom: 6px"
                                        >
                                            <div
                                                v-if="cat.scaled"
                                                class="progress-bar categoryAverageBar"
                                                :class="cat.scaled.color"
                                                :style="{width: decimalToPercent(cat.percentEarned) + '%'}"
                                            />
                                        </div>
                                        <template v-if="cat.scaled">
                                            <span
                                                v-if="!cat.prorated"
                                                style="margin-right: 2px"
                                                class="font kt-font-bold"
                                                :class="cat.scaled.color"
                                            >
                                                Average:&nbsp;&nbsp;
                                                <template v-if="showPercentages">
                                                    <samp>{{ decimalToPercent(cat.percentEarned) }}%</samp>
                                                    &nbsp;&nbsp;
                                                </template>
                                                <samp v-if="showScaledScores">
                                                    {{ cat.scaled.mark }}
                                                </samp>
                                            </span>
                                            <span
                                                v-else
                                                class="text-muted"
                                            >
                                                Prorated / No Grades In Category
                                            </span>
                                        </template>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kt-separator kt-separator--space-sm kt-separator--border-dashed" />
        </template>

        <div class="py-3 px-5">
            <div class="kt-section">
                <span class="kt-section__title kt-font-lg ml-1 mt-3">
                    Marking Period {{ markingPeriod.markingPeriod }} Assignments:
                </span>

                <b-table
                    id="average-table"
                    :items="assignments"
                    :fields="fields"
                    stacked="sm"
                    sort-null-last
                    responsive
                    fixed
                    selected-variant=""
                    show-empty
                    hover
                    small
                >
                    <template #cell(mark)="row">
                        <div
                            v-if="!row.item.graded || row.item.hideMarks"
                            class="font Grey"
                        >
                            -
                        </div>
                        <div
                            v-else
                            :class="[
                                'font', 'kt-font-bold', 'kt-font-lg',
                                row.item.averageMethod == 'Percentages' ? row.item.percentColor : row.item.pointsColor
                            ]"
                        >
                            {{ row.item.assignedGrade }}
                        </div>
                    </template>
                    <template #cell(categoryName)="row">
                        <div class="pt-2">
                            <div class="text-nowrap text-truncate">
                                <span
                                    :class="row.item.categoryColor"
                                    class="kt-font kt-font-transform-u"
                                >
                                    {{ row.item.categoryName }}
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #cell(courseWorkTitle)="row">
                        <div class="mt-1 mb-1">
                            <div class="course-title">
                                <a
                                    v-if="row.item.courseWorkId"
                                    href="#"
                                    class="kt-link font"
                                    :class="row.item.categoryColor"
                                    @click.stop.prevent="showDetails(row.item)"
                                >
                                    <span :class="row.item.deleted ? 'kt-font-danger' : ''">
                                        <samp
                                            v-if="row.item.teacherExtCourseWorkId"
                                            class="font"
                                            :class="row.item.categoryColor"
                                        >
                                            {{ row.item.teacherExtCourseWorkId }}
                                        </samp>
                                        {{ row.item.courseWorkTitle }}
                                    </span>
                                </a>
                                <a
                                    v-if="row.item.googleCourseWorkId"
                                    :href="row.item.alternateLink"
                                    target="_blank"
                                    class="kt-link font"
                                    :class="row.item.categoryColor"
                                >
                                    <SVGIcon
                                        name="google"
                                        class="mr-2"
                                    />
                                    {{ row.item.courseWorkTitle }}
                                </a>
                            </div>
                        </div>
                    </template>
                    <template #cell(publishDate)="row">
                        <div class="mt-1 mb-1">
                            {{ row.item.publishDate }}
                        </div>
                    </template>
                    <template #cell(dueDate)="row">
                        <div class="mt-1 mb-1">
                            {{ row.item.dueDate }}
                        </div>
                    </template>
                </b-table>
            </div>
        </div>

        <!-- <template v-if="selectedAssignment">
                    <StudentAssignmentDetails
                        ref="assignment-details"
                        :student-enrollment-id="studentEnrollmentId"
                        :google-course-work-id="selectedAssignment.googleCourseWorkId"
                        :school-term-marking-period-id="schoolTermMarkingPeriodId"
                        :course-section-id="courseSectionId"
                    />
                </template> -->
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import StudentBreakdownLineChart from './StudentBreakdownLineChart.vue';
import Types from '../store/Types';
import courseMixins from '../store/mixins/courseMixins';
import userMixins from '../store/mixins/userMixins';
import studentMixins from '../store/mixins/studentMixins';
import averagingMixins from '../store/mixins/averagingMixins';
import panelMixins from '../store/mixins/panelMixins';

export default Vue.extend({
    name: 'StudentAverageBreakdown',
    components: {
        StudentBreakdownLineChart,
    },
    mixins: [
        courseMixins,
        studentMixins,
        averagingMixins,
        userMixins,
        panelMixins,
    ],
    data() {
        return {
            selectedGradingCategory: 'Overall',
            selectedAssignment: null,
            gradeTemplateCategoryId: null,
            values: [15, 30, 20],
            max: 100,
            sortBy: 'numericSortDate',
            desc: true,
            fields: [
                {
                    key: 'mark',
                    label: 'Mark',
                    sortable: false,
                    thStyle: { width: '60px' },
                },
                {
                    key: 'categoryName',
                    sortable: true,
                    label: 'Type',
                    thStyle: { width: '130px' },
                },
                {
                    key: 'courseWorkTitle',
                    sortable: true,
                    label: 'Title',
                    thStyle: { width: '260px' },
                },
                {
                    key: 'publishDate',
                    label: 'Published',
                    sortable: true,
                    thStyle: { width: '100px' },
                },
                {
                    key: 'dueDate',
                    label: 'Due Date',
                    sortable: true,
                    thStyle: { width: '100px' },
                },
            ],
        };
    },
    computed: {
        schoolTermMarkingPeriodId: {
            get() {
                return this.$store.state.settings.schoolTermMarkingPeriodId;
            },
            set(schoolTermMarkingPeriodId) {
                this.$store.commit(Types.mutations.SAVE_SETTING_SCHOOL_TERM_MARKING_PERIOD, schoolTermMarkingPeriodId);
            },
        },
        forceStudentView() {
            return this.$store.state.forceStudentView;
        },
        showScaledScores() {
            if (!this.gradeTemplate) return false;
            const { schoolScaledDisplayPreference, homeScaledDisplayPreference } = this.gradeTemplate;
            let isSchoolUser = this.$_userMixins_isSchoolUser;
            let isHomeUser = this.$_userMixins_isHomeUser;
            if (this.forceStudentView && isSchoolUser) {
                isSchoolUser = false;
                isHomeUser = true;
            }
            if (isSchoolUser) return ['ShowScale', 'All'].includes(schoolScaledDisplayPreference);
            if (isHomeUser) return ['ShowScale', 'All'].includes(homeScaledDisplayPreference);
            return false;
        },
        showPercentages() {
            if (!this.gradeTemplate) return false;
            const { schoolScaledDisplayPreference, homeScaledDisplayPreference } = this.gradeTemplate;
            let isSchoolUser = this.$_userMixins_isSchoolUser;
            let isHomeUser = this.$_userMixins_isHomeUser;
            if (this.forceStudentView && isSchoolUser) {
                isSchoolUser = false;
                isHomeUser = true;
            }
            if (isSchoolUser) return ['ShowPercentage', 'All'].includes(schoolScaledDisplayPreference);
            if (isHomeUser) return ['ShowPercentage', 'All'].includes(homeScaledDisplayPreference);
            return false;
        },
        student() {
            const { studentEnrollmentId } = this;
            const students = this.$_studentMixins_getStudents({ studentEnrollmentId });
            const [student] = students;
            return student || null;
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        gradeTemplate() {
            if (!this.course) return null;
            return this.course.gradeTemplate;
        },
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        courseSectionId() {
            if (!this.course) return null;
            return this.course.courseSectionId;
        },
        averageCalculation() {
            if (!this.averages || this.averages.length == 0) return null;
            const { schoolTermMarkingPeriodId } = this;
            const row = this.averages.find((a) => a.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
            if (!row) return null;
            return row.averageCalculation.markingPeriod;
        },
        averages() {
            if (!this.course || !this.student) return null;
            return this.$_averagingMixins_getAveragesForStudentInCourse(this.studentEnrollmentId, this.course.courseSectionId);
        },
        assignments() {
            const { averageCalculation, selectedGradingCategory } = this;
            if (!averageCalculation) return [];

            const unique = new Map();

            averageCalculation.categories.forEach((category) => {
                category.assignments.forEach((a) => {
                    const assignment = { ...a };

                    assignment.assignedGrade = ''; // a.grade.syncGrades.assignedGrade;
                    assignment.graded = false;

                    if (a.grade && a.grade.syncGrades) {
                        assignment.assignedGrade = a.grade.syncGrades.assignedGrade;
                        assignment.averageMethod = a.grade.syncGrades.averageMethod;
                        assignment.graded = true;

                        if (a.grade.syncGrades.scaled && a.grade.syncGrades.scaled.points) {
                            assignment.points = a.grade.syncGrades.scaled.points;
                            assignment.pointsColor = a.grade.syncGrades.scaled.points.color;
                            assignment.pointsPossible = a.grade.syncGrades.pointsPossible;
                            assignment.pointsEarned = a.grade.syncGrades.pointsEarned;
                            assignment.percentColor = a.grade.syncGrades.scaled.percent.color;
                            assignment.percentEarned = a.grade.syncGrades.percentEarned;
                            assignment.scaledPointsMark = a.grade.syncGrades.scaled.points.mark;
                            assignment.scaledPercentMark = a.grade.syncGrades.scaled.percent.mark;
                        } else { // excused grade
                            assignment.pointsColor = a.grade.syncGrades.color;
                            assignment.percentColor = a.grade.syncGrades.color;
                        }
                    }
                    if (a.googleCourseWorkId) {
                        assignment.publishDate = a.sortDate.substring(0, 10);
                        if (a.dueDate) {
                            assignment.dueDate = a.dueDate.substring(0, 10);
                        }
                    }
                    assignment.categoryName = category.categoryName;
                    assignment.categoryColor = category.color;

                    const key = assignment.googleCourseWorkId ? `gcw_${assignment.googleCourseWorkId}` : `cw_${assignment.courseWorkId}`;
                    if (assignment.categoryName == selectedGradingCategory || selectedGradingCategory == 'Overall') {
                        if (!assignment.excluded && !assignment.deleted) unique.set(key, assignment);
                    }
                });
            });

            return [...unique.values()];
        },
        breakdown() {
            return this.averageCalculation;
        },
        markingPeriods() {
            return this.$store.state.database.markingPeriods;
        },
        markingPeriod() {
            const { schoolTermMarkingPeriodId } = this;
            if (!schoolTermMarkingPeriodId) return null;
            return this.markingPeriods.find((mp) => mp.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
        },
        category() {
            const { selectedGradingCategory } = this;
            return this.categories.find((cat) => cat.categoryName == selectedGradingCategory);
        },
        categories() {
            const { averageCalculation } = this;
            if (!averageCalculation) return [];

            const mp = { ...averageCalculation };
            delete mp.categories;
            mp.categoryName = 'Overall';
            mp.gradeTemplateCategoryId = -1;
            mp.percentEarned = mp.average;
            mp.color = 'Purple';
            const categories = averageCalculation.categories.filter((c) => !(c.categoryName == 'Uncategorized' && c.assignments.length == 0));
            return [].concat(mp, categories);
        },
    },
    mounted() {
        // this.fields.push({
        //     key: 'mark',
        //     label: 'Mark',
        //     sortable: false,
        //     thStyle: { width: '60px' },
        // });

        // const { schoolScaledDisplayPreference, homeScaledDisplayPreference } = this.gradeTemplate;
        // const isSchoolUser = this.$_userMixins_isSchoolUser;
        // const isHomeUser = this.$_userMixins_isHomeUser;

        // if ((isSchoolUser && ['ShowScale', 'All'].includes(schoolScaledDisplayPreference)) || (isHomeUser && ['ShowScale', 'All'].includes(homeScaledDisplayPreference))) {
        //     this.fields.push({
        //         key: 'scaledGrade',
        //         label: '',
        //         sortable: false,
        //         thStyle: { width: '60px' },
        //     });
        // }

        // if ((isSchoolUser && ['ShowPercentage', 'All'].includes(schoolScaledDisplayPreference)) || (isHomeUser && ['ShowPercentage', 'All'].includes(homeScaledDisplayPreference))) {
        //     this.fields.push({
        //         key: 'percentGrade',
        //         label: '',
        //         sortable: false,
        //         thStyle: { width: '60px' },
        //     });
        // }
    },
    methods: {
        showDetails(courseWork) {
            this.$_panelMixins_openPanelForCourseWork(courseWork);
        },
        setCategory(categoryName) {
            this.selectedGradingCategory = categoryName;
        },
        decimalToPercent(v) {
            let value = v;
            if (value === 0) return 0;
            if (!value) return '-';
            value *= 100;
            const decimals = 2;
            // @ts-ignore
            return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
        },
    },
});

</script>

<style scoped src='../css/colors.scss' lang="scss" />

<style>
#line-chart {
    border: none;
}
#average-table td {
    vertical-align: middle !important;
}
</style>

<style scoped>

.icon-column {
    max-width: 15px;
    width: 15px;
}

div.kt-widget20__chart:hover div.legend-container {
    display: none;
}
div.legend-container {
    background-color: white;
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
}
div.kt-widget14.legend {
    padding: 10px;
}
.kt-widget14__legends {
    padding-left: 0.5em;
}
.kt-radio.kt-radio--bold > span {
    margin-top: 16px;
}

</style>
