var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "heroSection", staticClass: "hero-section" }, [
    _c("div", { staticClass: "inner-hero" }, [
      _c("div", { staticClass: "left-col" }, [
        _c("div", { ref: "heroText", staticClass: "hero-text" }, [
          _c("h1", [_vm._v("Use SyncGrades Right Now")]),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "hero-btn" },
            [
              _vm.shouldRedirectUser
                ? _c("a", { attrs: { href: _vm.appLoginPage } }, [
                    _c("button", [_vm._v("Log In")]),
                  ])
                : _c("router-link", { attrs: { to: { name: "Login" } } }, [
                    _c("button", [_vm._v("Log In")]),
                  ]),
            ],
            1
          ),
        ]),
      ]),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Custom Data Solutions for "),
      _c("br"),
      _vm._v(" Schools & Districts"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right-col" }, [
      _c("img", {
        staticClass: "people",
        attrs: { src: "/images/landing/hero-people.png", alt: "hero" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }