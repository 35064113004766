var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-3 row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("span", {
          staticClass: "colorSwatch",
          staticStyle: { "background-color": "#baecdd" },
        }),
        _c("small", { staticClass: "text-muted" }, [_vm._v("Present")]),
      ]),
      _c("div", { staticClass: "col-6" }, [
        _c("span", {
          staticClass: "colorSwatch",
          staticStyle: { "background-color": "#fec8da" },
        }),
        _c("small", { staticClass: "text-muted" }, [_vm._v("Absent")]),
      ]),
      _c("div", { staticClass: "col-6" }, [
        _c("span", {
          staticClass: "colorSwatch",
          staticStyle: { "background-color": "#ffebc1" },
        }),
        _c("small", { staticClass: "text-muted" }, [_vm._v("Late")]),
      ]),
      _c("div", { staticClass: "col-6" }, [
        _c("span", {
          staticClass: "colorSwatch",
          staticStyle: { "background-color": "#cfd9f9" },
        }),
        _c("small", { staticClass: "text-muted" }, [_vm._v("Excused")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }