<template>
<div
    :key="`key_${key}`"
    class="kt-subheader kt-grid__item"
>
    <div class="kt-container kt-container--fluid ">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <CourseIcon
                    :course="course"
                    size="sm"
                    hex-color="#0f88ef"
                    class="mr-3"
                />
                {{ course.name }}
            </h3>
            <SubHeaderMarkingPeriodPicker />
            <!-- <span class="kt-subheader__separator kt-subheader__separator--v ml-3" /> -->
        </div>

        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <div v-if="$_userMixins_isSchoolUser">
                    <a
                        v-if="!isHomeUser"
                        v-b-tooltip.hover.top="`Create Anecdotal`"
                        href="#"
                        class="btn btn-icon btn-circle btn-label-twitter"
                        @click.stop.prevent="newAnecdotal"
                    >
                        <i class="la la-file-text" style="font-size: 1.4rem !important" />
                    </a>
                    <a
                        v-if="!isHomeUser && hasBadges"
                        v-b-tooltip.hover.top="`Add Award / Badge`"
                        href="#"
                        style="margin-left: 12px"
                        class="btn btn-icon btn-circle btn-label-linkedin"
                        @click.stop.prevent="newStudentBadge"
                    >
                        <i class="la la-smile-o" style="font-size: 1.5rem !important" />
                    </a>
                    <b-dropdown
                        v-if="!isHomeUser"
                        class="m-1"
                        toggle-class="px-0"
                        variant="none"
                        no-caret
                    >
                        <template #button-content>
                            <button
                                v-b-tooltip.hover.top="`Create Message`"
                                class="btn btn-icon btn-circle btn-label-google"
                            >
                                <i class="la la-comments" style="font-size: 1.5rem !important" />
                            </button>
                        </template>
                        <b-dropdown-item
                            variant="none"
                            button-class="dropdown-item"
                            link-class="kt-link"
                            @click="toggleCreateMessageToStudent"
                        >
                            <SVGIcon
                                name="communication"
                                class="kt-svg-icon kt-svg-icon--sm mr-2"
                            />
                            Message Student
                        </b-dropdown-item>
                        <b-dropdown-item
                            variant="none"
                            button-class="dropdown-item"
                            link-class="kt-link"
                            @click="toggleCreateMessageToGuardian"
                        >
                            <SVGIcon
                                name="guardians"
                                class="kt-svg-icon kt-svg-icon--sm mr-2"
                            />
                            Message Guardian
                        </b-dropdown-item>
                        <slot name="toolbar-dropdown" />
                    </b-dropdown>

                    <a
                        v-if="isHomeUser"
                        href="#"
                        class="btn btn-label-primary btn-sm btn-bold"
                        @click.stop.prevent="toggleCreateMessageToTeacher"
                    >
                        Message Teacher
                    </a>

                    <toggle-button
                        v-model="forceStudentView"
                        v-b-tooltip.hover.topleft
                        :height="28"
                        :width="56"
                        :font-size="18"
                        class="m-2 ml-3"
                        :title="forceStudentView ? 'Show School View' : 'Show Home View'"
                        :labels="{checked: '🏠', unchecked: '📚'}"
                    />
                </div>
                <div v-else>
                    <a
                        href="#"
                        class="btn btn-label-primary btn-sm btn-bold"
                        @click.stop.prevent="toggleCreateMessageToTeacher"
                    >
                        Message Teacher
                    </a>
                </div>
                <slot name="buttons" />
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import CourseIcon from '../components/CourseIcon.vue';
import userMixins from '../store/mixins/userMixins';
import studentGuardianMixins from '../store/mixins/studentGuardianMixins';
import SubHeaderMarkingPeriodPicker from '../components/SubHeaderMarkingPeriodPicker.vue';
import Types from '../store/Types';

export default {
    name: 'StudentCourseSubHeader',
    components: {
        CourseIcon,
        SubHeaderMarkingPeriodPicker,
    },
    mixins: [
        userMixins,
        studentGuardianMixins,
    ],
    props: {
        student: {
            type: Object,
            required: true,
        },
        course: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        user() {
            return this.$_userMixins_user;
        },
        hasBadges() {
            return this.$store.state.database.badges.length > 0;
        },
        initials() {
            return this.student.lastName.substring(0, 2);
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
        forceStudentView: {
            get() {
                return this.$store.state.forceStudentView;
            },
            set(enabled) {
                this.$store.commit(Types.mutations.SET_FORCE_STUDENT_VIEW, enabled);
            },
        },
        isHomeUser() {
            if (this.$store.state.forceStudentView) return true;
            return this.$_userMixins_isHomeUser;
        },
    },
    watch: {
        '$route.params.studentEnrollmentId': {
            handler() {
                this.key += 1;
            },
        },
    },
    mounted() {
    },
    methods: {
        toggleCreateMessageToTeacher() {
            const { course } = this;
            const [teacher] = course.teachers;
            if (!teacher) {
                this.showErrorMessage('No teacher found for this course');
                this.$store.commit(Types.mutations.SET_CREATE_MESSAGE_STAFF, null);
                this.$store.commit(Types.mutations.TOGGLE_CREATE_MESSAGE);
                return;
            }

            this.$store.commit(Types.mutations.SET_CREATE_MESSAGE_STAFF, teacher);
            this.$store.commit(Types.mutations.TOGGLE_CREATE_MESSAGE);
        },
        toggleCreateMessageToStudent() {
            this.$store.commit(Types.mutations.SET_CREATE_MESSAGE_STUDENT, this.student);
            this.$store.commit(Types.mutations.TOGGLE_CREATE_MESSAGE);
        },
        toggleCreateMessageToGuardian() {
            const { studentEnrollmentId } = this;
            const guardians = this.$_studentGuardianMixins_getStudentGuardians({ studentEnrollmentId });
            if (!guardians || guardians.length === 0) {
                this.showError('No guardians found for this student');
                this.$store.commit(Types.mutations.SET_CREATE_MESSAGE_GUARDIAN, null);
                this.$store.commit(Types.mutations.TOGGLE_CREATE_MESSAGE);
                return;
            }

            const firstEnabled = guardians.find((g) => g.emailAddresses && g.emailAddresses.length && g.emailAddresses.some((e) => e.enableSyncGradesHome)) || guardians[0];
            this.$store.commit(Types.mutations.SET_CREATE_MESSAGE_GUARDIAN, firstEnabled);
            this.$store.commit(Types.mutations.TOGGLE_CREATE_MESSAGE);
        },
        newStudentBadge() {
            const { studentEnrollmentId } = this.$route.params;
            const { commit, state } = this.$store;
            if (studentEnrollmentId) {
                const student = state.database.students.find((x) => x.studentEnrollmentId == studentEnrollmentId);
                if (!student) return commit(Types.mutations.SET_STUDENT_BADGE_PANEL_OPEN);
                return commit(Types.mutations.SET_STUDENT_BADGE_PANEL_OPEN, {
                    students: [student],
                    badgeType: null,
                });
            }
            commit(Types.mutations.SET_STUDENT_BADGE_PANEL_OPEN);
        },
        newAnecdotal() {
            const { studentEnrollmentId } = this.$route.params;
            if (studentEnrollmentId) {
                const student = this.$store.state.database.students.find((x) => x.studentEnrollmentId == studentEnrollmentId);
                if (!student) return this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN);
                return this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN, { students: [student] });
            }
            this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN);
        },
    },
};

</script>
