var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: `key_student-course-overview_${_vm.key}` },
    [
      _vm.student && _vm.course
        ? _c("StudentCourseSubHeader", {
            attrs: { student: _vm.student, course: _vm.course },
            scopedSlots: _vm._u(
              [
                {
                  key: "right-buttons",
                  fn: function () {
                    return [_c("StudentSubHeaderMenu")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1334697441
            ),
          })
        : _vm._e(),
      _vm.isPortfolioReady
        ? _c(
            "div",
            {
              staticClass:
                "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
            },
            [
              _vm.$_userMixins_isSchoolUser &&
              !_vm.$store.state.forceStudentView
                ? _c("StudentHeaderPortlet", {
                    attrs: { "display-wide": true },
                  })
                : _vm._e(),
              _vm.deviceType == "desktop"
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-7" },
                      [_c("StudentAverageBreakdown")],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-5" },
                      [
                        _c("StudentCourseAverages"),
                        _c("StudentCourseAttendance"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.deviceType == "tablet" || _vm.deviceType == "laptop"
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [_c("StudentCourseAttendance")],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [_c("StudentCourseAverages")],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [_c("StudentAverageBreakdown")],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.deviceType == "mobile"
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [_c("StudentCourseAttendance")],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [_c("StudentCourseAverages")],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [_c("StudentAverageBreakdown")],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }