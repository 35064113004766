var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-portlet" }, [
    _c("div", { staticClass: "kt-portlet__head kt-portlet__space-x" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c(
          "span",
          { staticClass: "kt-portlet__head-icon pr-0" },
          [
            _c("SVGIcon", {
              staticClass: "mr-3",
              attrs: { "hex-color": "#0f88ef", name: "averages" },
            }),
          ],
          1
        ),
        _vm.markingPeriod
          ? _c("h3", { staticClass: "kt-portlet__head-title" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.markingPeriod.markingPeriodName) + " Average"
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "kt-portlet__body" }, [
      _c("div", { staticClass: "kt-widget27" }, [
        _c(
          "div",
          {
            staticClass: "kt-widget27__visual",
            staticStyle: { height: "120px" },
          },
          [
            _vm.studentAverage && _vm.studentAverage.scaled
              ? _c(
                  "h3",
                  { staticClass: "kt-widget27__title w-100 text-center" },
                  [
                    _vm.showScaledScores
                      ? _c(
                          "span",
                          {
                            class: `font ${_vm.studentAverage.scaled.color} text-center`,
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.studentAverage.scaled.mark) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showPercentages
                      ? _c(
                          "div",
                          {
                            class: `font ${_vm.studentAverage.scaled.color} text-center pt-3`,
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$_utilMixins_decimal_to_percent(
                                    _vm.studentAverage.average,
                                    false
                                  )
                                ) +
                                " "
                            ),
                            _c("span", [_vm._v("%")]),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              : _c("h3", { staticClass: "kt-widget27__title kt-font-dark" }, [
                  _c("span", [_vm._v(" - ")]),
                ]),
          ]
        ),
        _vm._m(0),
      ]),
      _c(
        "div",
        { staticClass: "kt-widget4" },
        _vm._l(_vm.studentAverageCategories, function (c) {
          return _c(
            "div",
            {
              key: `gc_${c.gradeTemplateCategoryId}`,
              staticClass: "kt-widget4__item",
              attrs: {
                title: `Worth ${_vm.$_utilMixins_decimal_to_percent(
                  c.categoryWorth,
                  true
                )} of marking period average`,
              },
            },
            [
              _c(
                "span",
                { staticClass: "kt-widget4__icon" },
                [
                  _c("SVGIcon", {
                    staticClass: "kt-svg-icon1",
                    attrs: { name: "assignments", "hex-color": c.color },
                  }),
                ],
                1
              ),
              _c("span", { staticClass: "kt-font-bold font", class: c.color }, [
                _vm._v(" " + _vm._s(c.categoryName) + " "),
              ]),
              _c("div", { staticClass: "kt-widget4__number" }, [
                _vm.showScaledScores && c.scaled
                  ? _c(
                      "span",
                      {
                        staticClass: "font",
                        class: c.scaled ? c.scaled.color : "",
                      },
                      [_vm._v(" " + _vm._s(c.scaled.mark) + " ")]
                    )
                  : _vm._e(),
                _vm.showScaledScores && _vm.showPercentages
                  ? _c("span", [_vm._v(" - ")])
                  : _vm._e(),
                _vm.showPercentages && c.scaled
                  ? _c(
                      "span",
                      {
                        staticClass: "font",
                        class: c.scaled ? c.scaled.color : "",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$_utilMixins_decimal_to_percent(
                                c.percentEarned,
                                true
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "kt-widget27__container kt-portlet__space-x" },
      [_c("div", { staticClass: "tab-content px-4" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }