var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "kt-header-menu-wrapper ml-0" },
    [
      _c(
        "div",
        {
          staticClass:
            "kt-header-menu kt-header-menu-mobile kt-header-menu--layout-default ml-0",
        },
        [
          _c(
            "ul",
            { staticClass: "kt-menu__nav" },
            _vm._l(_vm.sections, function (section) {
              return _c(
                "li",
                {
                  key: `section_${section.title}`,
                  staticClass: "kt-menu__item kt-menu__item--submenu",
                  class: section.show
                    ? "kt-menu__item--active"
                    : "kt-menu__item--hover",
                },
                [
                  _c(
                    "a",
                    {
                      ref: "menu-link",
                      refInFor: true,
                      class: section.show
                        ? "btn kt-subheader__btn-daterange"
                        : "btn kt-subheader__btn-secondary kt-menu__link",
                      attrs: { id: `link_${section.title}`, href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.toggleSection(section.title)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "kt-menu__link-text" }, [
                        _vm._v(" " + _vm._s(section.title) + " "),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._l(_vm.sections, function (section) {
        return _c(
          "div",
          { key: `pop_${section.title}` },
          [
            section.items
              ? _c(
                  "b-popover",
                  {
                    attrs: {
                      triggers: "focus",
                      target: `link_${section.title}`,
                      placement: "bottom",
                      show: section.show,
                      "custom-class": "menu-popover mt-4",
                    },
                    on: {
                      "update:show": function ($event) {
                        return _vm.$set(section, "show", $event)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "kt-widget4" },
                      _vm._l(section.items, function (item) {
                        return _c(
                          "div",
                          {
                            key: `item_${item.path}`,
                            staticClass: "kt-widget4__item",
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "kt-widget4__icon" },
                              [
                                _c("SVGIcon", {
                                  attrs: {
                                    "hex-color": "#0f88ef",
                                    name: item.icon,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "kt-widget4__title kt-widget4__title--light",
                                attrs: {
                                  to: {
                                    name: item.name,
                                    params: {
                                      extCourseSectionId:
                                        _vm.extCourseSectionId,
                                    },
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "kt-menu__link-bullet kt-menu__link-bullet--dot",
                                  },
                                  [_c("span")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "kt-menu__link-text" },
                                  [_vm._v(" " + _vm._s(item.title) + " ")]
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-none kt-widget4__number kt-font-info",
                              },
                              [_vm._v(" +500 ")]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }