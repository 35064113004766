<template>
<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label">
            <span
                class="kt-portlet__head-icon pr-0"
            >
                <SVGIcon
                    hex-color="#0f88ef"
                    class="mr-3"
                    name="averages"
                />
            </span>
            <h3
                v-if="markingPeriod"
                class="kt-portlet__head-title"
            >
                <span>{{ markingPeriod.markingPeriodName }} Average</span>
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="kt-widget27">
            <div
                class="kt-widget27__visual"
                style="height: 120px"
            >
                <h3
                    v-if="studentAverage && studentAverage.scaled"
                    class="kt-widget27__title w-100 text-center"
                >
                    <span
                        v-if="showScaledScores"
                        :class="`font ${studentAverage.scaled.color} text-center`"
                    >
                        {{ studentAverage.scaled.mark }}
                    </span>

                    <div
                        v-if="showPercentages"
                        :class="`font ${studentAverage.scaled.color} text-center pt-3`"
                    >
                        {{ $_utilMixins_decimal_to_percent(studentAverage.average, false) }}
                        <span>%</span>
                    </div>
                </h3>
                <h3
                    v-else
                    class="kt-widget27__title kt-font-dark"
                >
                    <span>
                        -
                    </span>
                </h3>
            </div>
            <div class="kt-widget27__container kt-portlet__space-x">
                <div class="tab-content px-4" />
            </div>
        </div>

        <div class="kt-widget4">
            <div
                v-for="c in studentAverageCategories"
                :key="`gc_${c.gradeTemplateCategoryId}`"
                class="kt-widget4__item"
                :title="`Worth ${$_utilMixins_decimal_to_percent(c.categoryWorth, true)} of marking period average`"
            >
                <span class="kt-widget4__icon">
                    <SVGIcon
                        name="assignments"
                        class="kt-svg-icon1"
                        :hex-color="c.color"
                    />
                </span>
                <span
                    class="kt-font-bold font"
                    :class="c.color"
                >
                    {{ c.categoryName }}
                </span>
                <div class="kt-widget4__number">
                    <span
                        v-if="showScaledScores && c.scaled"
                        :class="c.scaled ? c.scaled.color : ''"
                        class="font"
                    >
                        {{ c.scaled.mark }}
                    </span>
                    <span v-if="showScaledScores && showPercentages">
                        -
                    </span>
                    <span
                        v-if="showPercentages && c.scaled"
                        :class="c.scaled ? c.scaled.color : ''"
                        class="font"
                    >
                        {{ $_utilMixins_decimal_to_percent(c.percentEarned, true) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Types from '../store/Types';
import studentMixins from '../store/mixins/studentMixins';
import courseMixins from '../store/mixins/courseMixins';
import utilMixins from '../store/mixins/utilMixins';
import userMixins from '../store/mixins/userMixins';
import { courseWorkGradeMixins, getCourseWorkGrades } from '../store/mixins/courseWorkGradeMixins';
import { scaleCourseWorkGrade } from '../lib/average/utils';

export default {
    name: 'StudentCourseAverages',
    mixins: [
        studentMixins,
        utilMixins,
        userMixins,
        courseMixins,
        courseWorkGradeMixins,
    ],
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        schoolTermMarkingPeriodId: {
            get() {
                return this.$store.state.settings.schoolTermMarkingPeriodId;
            },
            set(schoolTermMarkingPeriodId) {
                this.$store.commit(Types.mutations.SAVE_SETTING_SCHOOL_TERM_MARKING_PERIOD, schoolTermMarkingPeriodId);
            },
        },
        markingPeriod() {
            const { schoolTermMarkingPeriodId } = this;
            return this.$store.state.database.markingPeriods
                .find((m) => schoolTermMarkingPeriodId && m.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
        },
        student() {
            const { studentEnrollmentId } = this;
            const students = this.$_studentMixins_getStudents({ studentEnrollmentId });
            const [student] = students;
            return student || null;
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        gradeTemplate() {
            if (!this.course) return null;
            return this.course.gradeTemplate;
        },
        forceStudentView() {
            return this.$store.state.forceStudentView;
        },
        showScaledScores() {
            if (!this.gradeTemplate) return false;
            const { schoolScaledDisplayPreference, homeScaledDisplayPreference } = this.gradeTemplate;
            let isSchoolUser = this.$_userMixins_isSchoolUser;
            let isHomeUser = this.$_userMixins_isHomeUser;
            if (this.forceStudentView && isSchoolUser) {
                isSchoolUser = false;
                isHomeUser = true;
            }
            if (isSchoolUser) return ['ShowScale', 'All'].includes(schoolScaledDisplayPreference);
            if (isHomeUser) return ['ShowScale', 'All'].includes(homeScaledDisplayPreference);
            return false;
        },
        showPercentages() {
            if (!this.gradeTemplate) return false;
            const { schoolScaledDisplayPreference, homeScaledDisplayPreference } = this.gradeTemplate;
            let isSchoolUser = this.$_userMixins_isSchoolUser;
            let isHomeUser = this.$_userMixins_isHomeUser;
            if (this.forceStudentView && isSchoolUser) {
                isSchoolUser = false;
                isHomeUser = true;
            }
            if (isSchoolUser) return ['ShowPercentage', 'All'].includes(schoolScaledDisplayPreference);
            if (isHomeUser) return ['ShowPercentage', 'All'].includes(homeScaledDisplayPreference);
            return false;
        },
        courseWorkGrades() {
            const { student, course } = this;
            if (!course || !student) return [];
            const { database } = this.$store.state;
            const grades = getCourseWorkGrades(database, this.course).filter((g) => {
                if (g.courseWorkId) {
                    return g.studentEnrollmentId == student.studentEnrollmentId;
                }
                return g.googleUserId == student.googleUserId;
            });
            return grades;
        },
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
        markingPeriods() {
            const { markingPeriods } = this.$store.state.database;
            return markingPeriods;
        },
        studentAverage() {
            const { student, course, schoolTermMarkingPeriodId } = this;
            const { markingPeriodStudentAverages } = this.$store.state.database;

            if (!course || !student) return null;
            const row = markingPeriodStudentAverages
                .find((a) => a.courseSectionId == course.courseSectionId
                    && a.studentEnrollmentId == student.studentEnrollmentId
                    && a.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
            if (!row) return null;
            const average = row.averageCalculation.markingPeriod;
            return average || null;
        },
        studentAverageCategories() {
            if (!this.studentAverage) return [];
            return this.studentAverage.categories || [];
        },
    },
    methods: {
        scaleGrade(mark, courseWork) {
            const { gradeTemplate } = this;
            return scaleCourseWorkGrade(gradeTemplate, mark, courseWork);
        },
        selectMarkingPeriod(mp) {
            const { schoolTermMarkingPeriodId } = this;
            const { commit } = this.$store;
            if (schoolTermMarkingPeriodId == mp.schoolTermMarkingPeriodId) {
                commit(Types.mutations.SET_SCHOOL_TERM_MARKING_PERIOD_ID, null);
                return;
            }
            commit(Types.mutations.SET_SCHOOL_TERM_MARKING_PERIOD_ID, mp.schoolTermMarkingPeriodId);
        },
    },
};
</script>
