import * as lib from '../../lib/average';
import { isSchoolUser, isHomeUser } from './userMixins';

const { calculateAverageByExtCourseSectionId, calculateAverageForStudent, calculateAverageForStudentInCourse } = lib.calculate;

const averagingMixins = {
    methods: {
        $_averagingMixins_getAveragesForCourse(course) {
            const { markingPeriodStudentAverages } = this.$store.state.database;
            const { courseSectionIds } = course;
            const averages = markingPeriodStudentAverages.filter((r) => courseSectionIds.indexOf(r.courseSectionId) > -1);
            return averages;
        },
        $_averagingMixins_getAveragesForStudentInCourse(studentEnrollmentId, courseSectionId) {
            const { markingPeriodStudentAverages } = this.$store.state.database;
            return markingPeriodStudentAverages.filter((r) => r.courseSectionId == courseSectionId && r.studentEnrollmentId == studentEnrollmentId) || null;
        },
        $_averagingMixins_calculateAverageByExtCourseSectionId(extCourseSectionId) {
            calculateAverageByExtCourseSectionId(this.$store, extCourseSectionId);
        },
        $_averagingMixins_calculateAverageForStudent(studentEnrollmentId) {
            calculateAverageForStudent(this.$store, studentEnrollmentId);
        },
        $_averagingMixins_calculateAverageForStudentInCourse(courseSectionId, studentEnrollmentId) {
            calculateAverageForStudentInCourse(this.$store, courseSectionId, studentEnrollmentId);
        },
    },
};

function showPercentages(store, gradeTemplate) {
    const { user, forceStudentView } = store.state;
    if (!gradeTemplate || !user) return false;
    const { schoolScaledDisplayPreference, homeScaledDisplayPreference } = gradeTemplate;
    let schoolUser = isSchoolUser(user);
    let homeUser = isHomeUser(user);
    if (forceStudentView && schoolUser) {
        schoolUser = false;
        homeUser = true;
    }
    if (schoolUser) return ['ShowPercentage', 'All'].includes(schoolScaledDisplayPreference);
    if (homeUser) return ['ShowPercentage', 'All'].includes(homeScaledDisplayPreference);
    return false;
}

function showScaledScores(store, gradeTemplate) {
    const { user, forceStudentView } = store.state;
    const { schoolScaledDisplayPreference, homeScaledDisplayPreference } = gradeTemplate;
    let schoolUser = isSchoolUser(user);
    let homeUser = isHomeUser(user);
    if (forceStudentView && schoolUser) {
        schoolUser = false;
        homeUser = true;
    }
    if (schoolUser) return ['ShowScale', 'All'].includes(schoolScaledDisplayPreference);
    if (homeUser) return ['ShowScale', 'All'].includes(homeScaledDisplayPreference);
    return false;
}

export {
    averagingMixins as default,
    calculateAverageByExtCourseSectionId,
    showPercentages,
    showScaledScores,
};
