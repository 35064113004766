<template>
<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head--lg">
        <div class="kt-portlet__head-label">
            <span
                class="kt-portlet__head-icon pr-0"
            >
                <SVGIcon
                    hex-color="#0f88ef"
                    class="mr-3"
                    name="attendance"
                />
            </span>
            <h3 class="kt-portlet__head-title">
                <span>
                    {{ monthName }} {{ year }} Attendance
                </span>
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="row">
            <div class="col-12">
                <div
                    v-if="selectedMonth"
                    class="py-2"
                >
                    <b-calendar
                        :key="`${month}_${year}`"
                        :date-info-fn="dateClass"
                        class="pl-2 mt-2"
                        label-help=""
                        :show-decade-nav="false"
                        :no-key-nav="true"
                        :hide-header="true"
                        :readonly="true"
                        :initial-date="date"
                        block
                        @context="onContext"
                    />

                    <AttendanceLegend />
                </div>

                <p class="kt-font-lg pl-3 pt-4">
                    Attendance Totals
                </p>
                <div class="w-100 px-4">
                    <div class="col-12 pt-2 pb-2">
                        <div>
                            <span>Present</span>
                            <span class="pull-right">{{ yearToDate.present.percentage }}%</span>
                        </div>
                        <div class="progress progress-sm">
                            <div
                                class="progress-bar bg-success"
                                role="progressbar"
                                :style="`width: ${yearToDate.present.percentage}%`"
                            />
                        </div>
                    </div>
                    <div class="col-12 pt-2 pb-2">
                        <div>
                            <span>Absence</span>
                            <span class="pull-right">{{ yearToDate.absent.instances }}</span>
                        </div>
                        <div class="progress progress-sm">
                            <div
                                class="progress-bar bg-danger"
                                role="progressbar"
                                :style="`width: ${yearToDate.absent.percentage}%`"
                            />
                        </div>
                    </div>
                    <div class="col-12 pt-2 pb-2">
                        <div>
                            <span>Late</span>
                            <span class="pull-right">{{ yearToDate.late.instances }}</span>
                        </div>
                        <div class="progress progress-sm">
                            <div
                                class="progress-bar bg-warning"
                                role="progressbar"
                                :style="`width: ${yearToDate.late.percentage}%`"
                            />
                        </div>
                    </div>
                    <div class="col-12 pt-2 pb-2">
                        <div>
                            <span>Excused</span>
                            <span class="pull-right">{{ yearToDate.excused.instances }}</span>
                        </div>
                        <div class="progress progress-sm">
                            <div
                                class="progress-bar bg-primary"
                                role="progressbar"
                                :style="`width: ${yearToDate.excused.percentage}%`"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import moment from 'moment';
import studentMixins from '../store/mixins/studentMixins';
import courseMixins from '../store/mixins/courseMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import AttendanceLegend from './AttendanceLegend.vue';

export default {
    name: 'StudentCourseAttendance',
    components: {
        AttendanceLegend,
    },
    mixins: [studentMixins, courseMixins, portfolioMixins],
    data() {
        const data = {
            key: 0,
            context: null,
            selectedMonth: moment().format('YYYY-MM'),
        };
        return data;
    },
    computed: {
        student() {
            return this.$_studentMixins_getStudentFromRoute;
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        courseSectionId() {
            return this.course ? this.course.courseSectionId : null;
        },
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
        courseSectionStudentId() {
            const { courseSectionStudents } = this.$store.state.database;
            const { studentEnrollmentId, courseSectionId } = this;
            const css = courseSectionStudents.find((c) => c.courseSectionId == courseSectionId && c.studentEnrollmentId == studentEnrollmentId);
            return css ? css.courseSectionStudentId : null;
        },
        date() {
            if (!this.selectedMonth) return null;
            const date = `${this.selectedMonth}-01`;
            return date;
        },
        year() {
            if (!this.date) return null;
            return moment(this.date, 'YYYY-MM-DD').format('YYYY');
        },
        month() {
            if (!this.date) return null;
            return moment(this.date, 'YYYY-MM-DD').format('MM');
        },
        monthName() {
            if (!this.date) return null;
            return moment(this.date, 'YYYY-MM-DD').format('MMMM');
        },
        studentAttendance() {
            const { courseSectionStudentId, courseSectionId } = this;
            if (!courseSectionStudentId || !courseSectionId) return [];
            const { courseSectionAttendance } = this.$store.state.database;
            return courseSectionAttendance
                .filter((row) => row.courseSectionStudentId == courseSectionStudentId && row.courseSectionId == courseSectionId);
        },
        yearToDate() {
            const {
                studentAttendance,
                createPercentage,
                formatInstances,
            } = this;

            const totals = {
                absent: 0,
                present: 0,
                late: 0,
                excused: 0,
                denominator: 0,
            };

            const absent = studentAttendance.filter((r) => r.attendanceState == 'Absent');
            const present = studentAttendance.filter((r) => r.attendanceState == 'Present');
            const late = studentAttendance.filter((r) => r.attendanceState == 'Late');
            const excused = studentAttendance.filter((r) => r.attendanceState == 'Excused');
            const denominator = studentAttendance.length;

            totals.absent += absent.length;
            totals.present += present.length;
            totals.late += late.length;
            totals.excused += excused.length;
            totals.denominator += denominator;

            return {
                absent: { instances: formatInstances(totals.absent), percentage: createPercentage(totals.absent, totals.denominator) },
                present: { instances: formatInstances(totals.present), percentage: createPercentage(totals.present, totals.denominator) },
                late: { instances: formatInstances(totals.late), percentage: createPercentage(totals.late, totals.denominator) },
                excused: { instances: formatInstances(totals.excused), percentage: createPercentage(totals.excused, totals.denominator) },
            };
        },
    },
    watch: {
        studentEnrollmentId() {
            this.key += 1;
        },
        courseSectionId() {
            this.key += 1;
        },
    },
    methods: {
        onContext(ctx) {
            this.selectedMonth = `${ctx.activeYMD.substring(0, 7)}`;
        },
        rowSelected(item) {
            this.monthName = `${item.year}-${item.month}`;
        },
        dateClass(dateString) {
            // color the date background
            const v = this;
            const { studentAttendance } = v;
            const row = studentAttendance.find((r) => r.attendanceDate == dateString);
            if (!row) return null;

            const present = row.attendanceState == 'Present';
            const absent = row.attendanceState == 'Absent';
            const late = row.attendanceState == 'Late';
            const excused = row.attendanceState == 'Excused';

            if (present) return 'table-success';
            if (absent) return 'table-danger';
            if (late) return 'table-warning';
            if (excused) return 'table-info';
            return null;
        },
        formatPercentage(p) {
            if (!p) return '-';
            return `${p}%`;
        },
        formatInstances(total) {
            if (!total) return '';
            if (total == 1) return '1 time';
            return `${total} times`;
        },
    },
};
</script>

<style scoped>

tr.selected td {
    background-color:#f8f9fa;
}

td {
    vertical-align: middle;
    cursor: pointer;
}

td.course-total span {
    white-space: no-wrap;
}
.kt-radio--single {
    width: 18px;
}
</style>
